
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue'
import Button from '@/components/UI/Button.vue'
import Input from '@/components/UI/Input.vue'
import ListingTable from '@/components/UI/ListingTable.vue'
import Pagination from '@/components/UI/Pagination.vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import Loader from '@/components/UI/Loader.vue'
import { useStore } from '@/store'
import { superAdminCompanyActionTypes } from '@/store/superAdminCompany/actions'
export default defineComponent({
  name: 'Home',
  setup() {
    const searchBy = ref('')
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const totalCompanies = computed(() => store.state.superAdminCompany.totalCompanies)
    const loader = computed(() => store.state.superAdminCompany.loader)
    const getAllCompanies = computed(() => store.state.superAdminCompany.allCompanies)
    const initializeRequest = async (query) => {
      await store.dispatch(superAdminCompanyActionTypes.GET_ALLCOMPANIES, { query: query })
    }
    onMounted(async () => {
      initializeRequest(route.query)
    })
    watch(
      () => route.query,
      () => {
        if (route.name == 'Companies') {
          initializeRequest(route.query)
        }
      }
    )
    const onInput = (value) => {
      searchBy.value = value
    }
    const onEnter = () => {
      const searchedTerm = (searchBy.value as string).trim().toLowerCase()
      const query: { query?: string } = Object.assign({}, route.query, { query: searchedTerm, page: 1 })
      if (!searchedTerm) {
        delete query.query
      }
      router.push({ query })
    }
    const companiesHeadings = reactive([
      { label: 'Company name', key: 'name' },
      { label: 'Address', key: 'address' },
      { label: 'Postcal code', key: 'postalCode' },
      { label: 'City', key: 'city' }
    ])
    const setPage = (pageNumber) => {
      const query = Object.assign({}, route.query, { page: pageNumber })
      router.push({ query })
    }
    const sortBy = (value) => {
      const query = Object.assign({}, route.query, { orderBy: value['orderBy'], order: value['order'] })
      router.push({ query })
    }
    const openNewCompany = () => {
      router.push({ name: 'Company' })
    }
    const editCompany = (companyId) => {
      router.push({ name: 'superAdminCompanyDetails', params: { id: companyId } })
    }
    return {
      openNewCompany,
      searchBy,
      onInput,
      onEnter,
      loader,
      totalCompanies,
      companiesHeadings,
      getAllCompanies,
      route,
      setPage,
      sortBy,
      editCompany
    }
  },
  components: {
    Button,
    Input,
    ListingTable,
    Loader,
    Pagination
  }
})

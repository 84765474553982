
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  emits: ['update:value', 'onFocus', 'update:phoneCode', 'onEnter', 'onChange'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    isFacility: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    searchInput: {
      type: Boolean,
      default: false
    },
    currencyPlaceholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    fiterSidebarSearch: {
      type: Boolean,
      default: false
    },
    isphone: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    isEmployeeSearch: {
      type: Boolean,
      default: false
    },
    isBrandsAndModelsSearch: {
      type: Boolean,
      default: false
    },
    currencyPlaceholderPosition: {
      type: String,
      default: ''
    },
    isHierarchyInput: {
      type: Boolean,
      default: false
    },
    backgroundType: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputRef = ref(null)
    onMounted(() => {
      if (props.isHierarchyInput) inputRef.value.focus()
    })
    return { inputRef }
  }
})
